import { template as template_41291d278fee45c1aaa851cf0db2b6a7 } from "@ember/template-compiler";
const FKControlMenuContainer = template_41291d278fee45c1aaa851cf0db2b6a7(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
