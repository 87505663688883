import { template as template_83e27e9fdba14f5dbddcf0611d3c58c1 } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const LikesCell = template_83e27e9fdba14f5dbddcf0611d3c58c1(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="likes"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="likes"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default LikesCell;
